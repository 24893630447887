<template lang="html">

  <section class=" header">

    <div class="container top">
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/img/logo-negro.svg" alt="R-sami logo">
      </router-link>
      <ul class="item-list d-flex aic jcfe" :showMenuResponsive="showMenuResponsive">
        <span class="detail-1"></span>
        <span class="detail-2"></span>
        <li>Contacta:</li>
        
        <a class="correo negro" href="mailto:support@r-sami.com"><img src="/img/mail-negro.svg"></a>
        <a class="negro" href="tel:+34919498458"><img src="/img/telefono-negro.svg"></a>



        <a class="correo blanco" href="mailto:support@r-sami.com"><img src="/img/mail-blanco.png"></a>
        <a class="blanco" href="tel:+34919498458"><img src="/img/telefono-blanco.png"></a>

        <li class="btn-header"><a href="/es/demo" >Solicita DEMO GRATIS</a></li>
      </ul>

      <button class="menu" @click="toggleShowSubMenuResponsive" :showMenuResponsive="showMenuResponsive">
        <svg viewBox="0 0 64 48">
          <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
          <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
          <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
        </svg>
      </button>

    </div>

<!-- 
    <div class="container bottom">

      <ul class="item-list d-flex aic jcfe" :showMenuResponsive="showMenuResponsive">
        <span class="detail-1"></span>
        <span class="detail-2"></span>
        <li><a>Solución DSPs</a></li>
        <li><a href="https://r-sami.com/es/demo" target="_blank">Solicitar DEMO</a></li>
      </ul>
      <router-link :to="{ name: 'home' }" class="logo normal">
        <img src="/img/logo.svg" alt="R-sami logo">
      </router-link>
      <button class="menu" @click="toggleShowSubMenuResponsive" :showMenuResponsive="showMenuResponsive">
        <svg viewBox="0 0 64 48">
          <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
          <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
          <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
        </svg>
      </button>
    </div> -->
  </section>

</template>

<script lang="js">
import { useI18n } from 'vue-i18n'


export default {
  name: 'header',
  components: {
  },
  props: [],
  data() {
    return {
      showMenuResponsive: false,
    }
  },
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' })
    return { t, locale }
  },
  methods: {
    toggleShowSubMenuResponsive: function () {
      this.showMenuResponsive = !this.showMenuResponsive;
      if (this.showMenu == true) {
        this.showMenu = false
      }
    },
  },
  watch: {
    "$route.path"() {
      this.showMenuResponsive = false
    }
  }
}


</script>

<style scoped lang="scss">
@import "../styles/general.scss";
.blanco{
  display:none
}
.logo.normal{
  display:none;
}
.btn-header {
  background-color: #0AA6DF;
  color: white;
  font-size: 18px;
  letter-spacing: 0.3;
  font-weight: 700;
  padding: 12px 18px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;

  & :hover {
    color: white !important;
  }
}

.header {
  background: white;
  border-bottom: 2px solid white;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

  &.fixed-class {
    padding: 5px;
    background: #1e2f3feb;
    border-bottom: 0;
    box-shadow: 0px 6px 21px -12px rgba(0, 0, 0, 0.75);
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

    .container .logo {
      max-width: 190px;
      transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      max-width: 200px;
      transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

      img {
        width: 150px;
      }
    }

    .item-list {
      list-style: none;
      /* width: calc(100% - 600px); */
      // width: calc(100% - 350px);
      width: 100%;
      padding: 0 30px;
      /* justify-content: flex-start; */

      li {
        margin-left: 40px;
        //width: calc(100% / 6 - 40px);
        width: auto;
        color: white;
        font-size:17px;

        a {
          color: white;
          text-decoration: none;
          font-size: 17px;
          font-weight: 300;
          position: relative;

          &::before {
            content: '';
            width: 0;
            height: 1px;
            background: $blueLight;
            position: absolute;
            bottom: -3px;
            right: 0;
            left: 0;
            transition: all 0.5s ease-in-out;
          }

          &.router-link-active,
          &:hover {
            color: $blueLight;

            &::before {
              width: 100%;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }

    .login-search {
      max-width: 180px;
      padding: 0;

      .btn-login {
        color: white;
        background: $blueLight;
        padding: 10px 20px;
        text-decoration: none;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 500;
      }

      .btn-search {
        width: 30px;
        height: 30px;
        background-image: url('/img/icons/icon-search.svg');
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        background-size: 29px;
      }
    }
  }
}
.correo{
  margin:0px 10px}
li a {
  cursor: pointer;
}

.menu {
  display: none;
}

.detail-1,
.detail-2 {
  display: none;
}

.header .container.bottom {
  padding: 20px 0px;
  /* display: flex; */
  /* justify-content: flex-start; */
  text-align: left;

  & ul {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0px;
    margin: 0px;

    & li:first-child {
      margin: 0px !important;
    }
  }

}

.container.top {
  padding: 10px 0px;
  margin: 0px;
  background: white;
  color: black;
  width: 100%;
  width: 100%;
    max-width: 1200px;
    margin: 0 auto;



  & li {
    color: #373F41 !important;
  }
}
.container.header{
  max-width:1200px;
  width:100%
}
@media screen and (max-width:1395px) {
  .container.top {
    padding: 10px 0px;
  }
}

@media screen and (max-width:1260px) {
  .container.top {
    padding: 10px 0px;
  }
}

@media screen and (max-width:1121px) {
  .header .container .item-list {
    width: calc(100% - 220px);
  }

  .header .container .item-list li {
    margin-left: 40px;
    width: auto;
  }
}

@media screen and (max-width:897px) {
  .blanco{
    display:block;
    margin-bottom:5px;
    margin-top:5px;
    & img{
      width:30px;

    }
  }

  .btn-header{
    margin-top:20px
  }

  .negro{
    display:none
  }

  .header .container.bottom ul {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
    align-items: center;
  }

  .header .container.bottom {
    padding: 20px 20px;
    justify-content: space-between;
    text-align: left;
    display: flex;
  }
  .logo.normal{
    display:block;
  }

  .header .container .logo img {
    width: 165px;
    margin-left:0px;
  }

  .container.top {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
}

  .menu {
    display: block;
    --color: black;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    outline: none;
    position: relative;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;

    svg {
      width: 64px;
      height: 48px;
      top: -6px;
      left: -14px;
      stroke: white;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      display: block;
      position: absolute;

      path {
        transition: stroke-dasharray var(--duration, .85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, .85s) var(--easing, ease) var(--delay, 0s);
        stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
        stroke-dashoffset: var(--offset, 126px);
        transform: translateZ(0);

        &:nth-child(2) {
          --duration: .7s;
          --easing: ease-in;
          --offset: 100px;
          --array-2: 74px;
        }

        &:nth-child(3) {
          --offset: 133px;
          --array-2: 107px;
        }
      }
    }

    &[showmenuresponsive="true"] {
      svg {
        path {
          --offset: 57px;

          &:nth-child(1),
          &:nth-child(3) {
            --delay: .15s;
            --easing: cubic-bezier(.2, .4, .2, 1.1);
          }

          &:nth-child(2) {
            --duration: .4s;
            --offset: 2px;
            --array-1: 1px;
          }

          &:nth-child(3) {
            --offset: 58px;
          }
        }
      }
    }
  }

  .header .container .item-list {
    position: fixed;
    bottom: 100%;
    right: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $blueDark;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

    &[showmenuresponsive="true"] {
      bottom: 0;
      transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

    }

    .detail-1 {
      display: block;
      width: 50vw;
      height: 50vh;
      background-image: url('/img/icons/detail-line2.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: -81px;
      top: -92px;
      animation: flash 15s infinite;
      /* opacity: 0.5; */

    }

    .detail-2 {
      display: block;
      width: 50vw;
      height: 50vh;
      background-image: url('/img/icons/detail-line1.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -72px;
      bottom: 0;
      animation: flash 30s infinite;
    }
  }



  .header .container .item-list li {
    margin-left: 0;
    color:white !important;
  }

  .header .container .item-list li a {
    font-size: 2.5vh;
    display: inline-block;
  }

  .header .container {
    justify-content: space-around;
  }
}

@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 0.8;
  }

  25%,
  75% {
    opacity: 0.2;
  }
}
</style>