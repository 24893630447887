import { createApp } from 'vue'
import { createPinia } from "pinia"
import router from './router'

import utils from "@/utils/utils.js";
import { createI18n } from 'vue-i18n'
import App from './App.vue'
//I18N 
const messages = utils.loadLocaleMessages();
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: process.env.VUE_APP_I18N_LOCALE || 'es',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
    messages: messages
});
createApp(App)
    .use(router)
    .use(i18n)
    .use(createPinia())
    .mount('#app')
