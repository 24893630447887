import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [
  {

      path: '/es/',
      name: 'home',
      component: () =>
        import('@/views/home.vue'),
    },
    {
      path: '/es/demo',
      name: 'demo',
      component: () =>
        import('@/views/demo.vue'),
      },
      { path: '/', redirect: '/es/' },
      { path: '/demo', redirect: '/es/demo' },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }

  },
  routes
})

export default router
